import React from 'react';
import pumpfun from './assets/pumpfun.png';
import nipi from './assets/nipi.jpeg';
import twitter from './assets/twitter.png';
import pitcat from './assets/pitcat.jpeg';
import telegram from './assets/telegram.png';
import dexscreener from './assets/dexscreener.png';
import dexscreenerwhite from './assets/dexscreenerwhite.png';

const handleClick = () => {
    navigator.clipboard.writeText("HQnAhNco4n5Xxhwnz17Rk3oPyQ3D73QgWVFz9JAKoM7L");
};

const LandingPage = () => {
  return (
    <div className="bg-[#fcf9f7] min-h-screen">
        <div
        className="min-h-screen flex flex-col justify-center"
        >
            <div className="pt-2 px-32 flex justify-between items-center">
                <a href="/">
                    <img src={nipi} className="h-12 w-12 rounded rounded-full"></img>
                </a>
                <div className="text-[#111111]">
                    <p className="font-bold text-3xl">nipi's brother</p>
                </div>
            </div>
            <main className="flex-1 flex flex-col items-center">
                <p className="text-6xl p-8 text-[#111111] font-bold">PitCat, nipi's brother is now on Solana.</p>
                <img src={pitcat} className="w-1/5 rounded rounded-xl"></img>
                <p className="text-3xl my-4 cursor-pointer text-[#111111]" onClick={handleClick}>CA: HQnAhNco4n5Xxhwnz17Rk3oPyQ3D73QgWVFz9JAKoM7L</p> 
                <a href="https://pump.fun/HQnAhNco4n5Xxhwnz17Rk3oPyQ3D73QgWVFz9JAKoM7L" className="border rounded rounded-xl bg-[#111111] text-white text-xl md:px-6 md:py-4 px-2 py-1 inline-flex items-center hover:bg-[#343434]">
                    <p className="mr-2 font-semibold">Buy $PITCAT</p>
                    <img src={pumpfun} className="w-6 h-6"></img>
                </a>
            </main>
            <footer className="w-full mb-4 font-semibold text-center text-[#111111]">
                <div className="flex justify-center">
                    <div className="flex-1 flex justify-center">
                        <a href="https://twitter.com/PitCatSol" className="text-4xl hover:text-[#343434] text-[#111111] inline-flex items-center rounded rounded-xl p-4">
                            <p className="mr-2">Twitter</p>
                            <img src={twitter} className="h-10"></img>
                        </a>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <a href="https://t.me/+N2pbnCsAX5oyNWU1" className="text-4xl hover:text-[#343434] text-[#111111] inline-flex items-center rounded rounded-xl p-4">
                            <p className="mr-2">Telegram</p>
                            <img src={telegram} className="h-10"></img>
                        </a>
                    </div>
                    <div className="flex-1 flex justify-center">
                        <a href="#" className="text-4xl hover:text-[#343434] text-[#111111] inline-flex items-center rounded rounded-xl p-4">
                            <p className="mr-2">Dexscreener</p>
                            <img src={dexscreener} className="h-10"></img>
                        </a>
                    </div>
                </div>
                &copy; 2024 by $NIPI. All rights reserved.
            </footer>
        </div>
    </div>
  );
};

export default LandingPage;